import React from "react"
import partner1Image from "../../../components/assets/img/pages/parceiros/ic-partner-1.png"
import partner2Image from "../../../components/assets/img/pages/parceiros/ic-partner-2.png"
import partner3Image from "../../../components/assets/img/pages/parceiros/ic-partner-3.png"
import partner4Image from "../../../components/assets/img/pages/parceiros/ic-partner-4.png"
import partner5Image from "../../../components/assets/img/pages/parceiros/ic-partner-5.png"
import partner6Image from "../../../components/assets/img/pages/parceiros/ic-partner-6.png"
import partner7Image from "../../../components/assets/img/pages/parceiros/ic-partner-7.png"

const Partners = () => (
    <section className="partners">
        <img src={partner1Image} alt="logo" />
        <img src={partner2Image} alt="logo" />
        <img src={partner3Image} alt="logo" />
        <img src={partner4Image} alt="logo" />
        <img src={partner5Image} alt="logo" />
        <img src={partner6Image} alt="logo" />
        <img src={partner7Image} alt="logo" />
    </section>
)

export default Partners
